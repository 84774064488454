.office {
  margin-top: 150px;
}

.office__about-us-txt {
  text-align: justify;
  text-indent: 1rem;
}

.office__about-us-txt h3 {
  font-weight: 900;
}

.office__about-us-txt span {
  text-align: justify;
  text-indent: 1rem;
}

.office__about-us-img {
  height: 500px;
  filter: sepia();
  background-image: url("002.18f03ae7.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1200px;
}

@media (max-width: 991px) {
  .office {
    margin-top: 46px;
  }
}

/*# sourceMappingURL=office.d83101d9.css.map */
