.office {
  margin-top: 150px;
}

.office__about-us-txt {
  text-align: justify;
  text-indent: 1rem;
}

.office__about-us-txt h3 {
  font-weight: 900;
}

.office__about-us-txt span {
  text-align: justify;
  text-indent: 1rem;
}

.office__about-us-img {
  background-image: url('../img/002.webp');
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  background-size: 1200px;
  filter: sepia(1);
}

@media (max-width: 991px) {
  .office {
    margin-top: 46px;
  }
}
